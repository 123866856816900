import React from 'react'
import CreatableSelect from 'react-select/creatable'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const MediaCategory = (props) => {
    const {
        selectedCategory, 
        setSelectedCategory,
        loading,
        disableCategory
    } = props

    let categoryOption = []
    if (props.media.category.length > 0) {
        props.media.category.map(val => (
            !['all media', 'preview'].includes(val.slug) && categoryOption.push({ value: val._id, label: val.name })
        ))
    }
    return (
        <CreatableSelect
            styles={{
                menu: provided => ({ ...provided, zIndex: 10, textAlign: 'left' })
            }}
            className='text-dark'
            isMulti={false}
            value={selectedCategory}
            options={categoryOption}
            onChange={(value) => setSelectedCategory(value)}
            noOptionsMessage={() => 'No Category Available'}
            isDisabled={loading || disableCategory}
            placeholder={'Select Category'}
            isClearable={true}
        />
    )
}

MediaCategory.propTypes = {
    mediaManager: PropTypes.object.isRequired,
    selectedCategory: PropTypes.object.isRequired,
    setSelectedCategory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    media: state.media
})

export default connect(
    mapStateToProps, {}
)(withRouter((MediaCategory)))